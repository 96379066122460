<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="white">
            <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" max-width="300px" prepend-inner-icon="search" label="Pesquisar na Tabela"></v-text-field>
            <v-spacer></v-spacer>
            <input id="inputArquivo" type="file" hidden :multiple="false" @change="carregarConteudo" :disabled="carregando">
            <v-btn color="primary" @click="carregarItens()" icon><v-icon>cached</v-icon></v-btn>
            <v-btn color="primary" @click="escolherArquivo()" icon><v-icon>add</v-icon></v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :headers="headers" :items="itens" :search="busca" :loading="carregando">
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.codigo }}</td>
              <td>{{ props.item.descricao }}</td>
              <td>{{ props.item.estado }}</td>
              <td class="text-center">
                <v-btn icon small @click="detalharItem({item : props.item, router : $router})">
                  <v-icon>details</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
</div>
</template>
<script>

  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    data () {
      return {
        headers: [
          { text: 'NCM', value: 'codigo', sortable: false },
          { text: 'Descrição', value: 'descricao' },
          { text: 'Estado', value: 'estado' },
          { text: 'Detalhar', value: 'name', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: ''
      }
    },

    computed: {
        ...mapState('ibpt', {
            itens: 'itens',
            carregando: 'carregando',
        }),
    },

    methods: {
        
        escolherArquivo(){
          document.getElementById('inputArquivo').click();
        },

        carregarConteudo(evento){
            const arquivo = evento.target.files[0];
            if (arquivo) {
                
                var reader = new FileReader();
                var vm = this;
                reader.onload = (e) => {
                    vm.enviarConteudo(e.target.result);
                }
                reader.readAsText(arquivo, 'ASCII');
            }
        },

        enviarConteudo(dados){
            this.$store.dispatch('ibpt/tratarConteudo', dados);
        },

        carregarItens(){

          this.$store.dispatch('ibpt/carregarItens');
        },

        detalharItem(dados){

          this.$store.dispatch('ibpt/detalhar', dados);
        }
    }
  }
</script>